import Head from "next/head";
import { Header, Navigation } from "@/components";
import { LayoutInterface } from "@/interfaces/Layout.interface";
import { useEffect } from "react";
import { useStaff } from "@/context/staff";

declare global {
  interface Window {
    scan: any;
    EXIF: any;
  }
}

const PageImage = () => {
  return <div className={"page-image"}></div>;
};

export const Workspace = ({
  children,
  title,
  classNames,
  customStyles,
  backImage = true,
  isOpenList,
}: LayoutInterface) => {
  const { initSession } = useStaff();

  useEffect(() => {
    initSession();
  }, []);

  return (
    <>
      <Head>
        <title>{title || "MAFI"}</title>
        <meta name="description" content="Generated by create next app" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />

        <link rel="icon" href="/favicon.ico" />
        <link rel="manifest" href="/manifest.json" />
        <link
          rel="apple-touch-icon"
          sizes="76x76"
          href="/apple-touch-icon.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href="/favicon-32x32.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href="/favicon-16x16.png"
        />
        <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#5bbad5" />
        <meta name="msapplication-TileColor" content="#603cba" />
        <meta name="theme-color" content="#ffffff" />
      </Head>
      <main className="workspace">
        <Navigation isOpenList={isOpenList} />
        <div className="page-content">
          <Header />
          {backImage && <PageImage />}
          <div className={"page-content-data"} style={customStyles}>
            {children}
          </div>
        </div>
      </main>
    </>
  );
};

import Icons from "../Icons";
import styles from "./styles.module.scss";
import { Avatar } from "../Avatar";
import { deleteCookie } from "cookies-next";
import { useRouter } from "next/router";
import { useSession } from "@/context/session";
import { Logotype } from "../Logotype";

const Header = () => {
  const { session } = useSession();
  const router = useRouter();
  const logout = async () => {
    // await FetchAPI("DELETE", "/api/pin")
    await deleteCookie("mafi-user-token");
    router.push("/");
  };

  return (
    <header className={styles.header}>
      <div className={styles.logo}></div>
      <div className={styles.session}>
        <div className={styles.user}>
          <div className={styles.userData}>
            <div className={styles.name}>
              {session?.name} {session?.surname}
            </div>
          </div>
          <div className={styles.avatarData}>
            <Avatar data={session} size={36} />
          </div>
        </div>
        <button className={styles.logout} onClick={logout}>
          <Icons.Out />
        </button>
      </div>
    </header>
  );
};

export default Header;

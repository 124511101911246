import Layout from "@/containers/layout";
import { mainMiddleware } from "@/middlewares/main";
import { MainWidget } from "@/components";

function Home() {
  return (
    <Layout.Main noLayout={true}>
      <MainWidget />
    </Layout.Main>
  );
}

export const getServerSideProps = async (context: any) => {
  return await mainMiddleware(context, async () => {
    return {
      props: {},
    };
  });
};

export default Home;
